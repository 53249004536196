<template>
  <a-modal :confirm-loading="loading" title="编辑数据库认证信息" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="连接地址" prop="address">
        <a-input v-model="form.address" :max-length="128" placeholder="请输入数据库连接地址"></a-input>
      </a-form-model-item>
      <template v-if="databaseType !== 'memcached'">
        <a-form-model-item
          v-if="databaseType !== 'redis'"
          label="用户"
          prop="user"
          :rules="[{ message: '请输入数据库用户', required: true, trigger: 'blur' }]"
        >
          <a-input v-model="form.user" :max-length="128" placeholder="请输入数据库用户"></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input-password v-model="form.password" autocomplete="new-password" :max-length="128" placeholder="请输入数据库密码"></a-input-password>
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateDatabaseAuth } from '@/api/database'

export default {
  name: 'AuthUpdateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        address: '',
        user: '',
        password: ''
      },
      rules: {
        address: [{ message: '请输入数据库连接地址', required: true, trigger: 'blur' }]
      },
      id: '',
      databaseType: ''
    }
  },
  methods: {
    show (id, databaseType) {
      this.id = id
      this.databaseType = databaseType
      this.visible = true
    },
    reset () {
      this.form = {
        address: '',
        user: '',
        password: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateDatabaseAuth(this.id, this.form).then(res => {
            this.$message.success(res.message)
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
