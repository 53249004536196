<template>
  <a-modal :confirm-loading="loading" title="编辑数据库基本信息" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="数据库名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入数据库名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属系统" prop="projects">
        <project-select v-model="form.projects" :label-in-value="true" mode="multiple"></project-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getDatabase, updateDatabase } from '@/api/database'
import ProjectSelect from '@/components/select/ProjectSelect'

export default {
  name: 'BaseUpdateForm',
  components: { ProjectSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: '',
        projects: undefined
      },
      rules: {
        name: [{ message: '请输入数据库名称', required: true, trigger: 'blur' }],
        projects: [{ message: '请选择系统', required: true, trigger: 'change' }]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getDatabase(id).then(res => {
        const data = res.data
        this.form.name = data.name
        if (data.projects.length) {
          const arr = []
          data.projects.forEach(project => {
            arr.push({ key: project.id, label: project.name })
          })
          this.form.projects = arr
        }
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = {
        name: '',
        projects: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {}
          data.name = this.form.name
          if (this.form.projects) {
            const arr = []
            this.form.projects.forEach(project => {
              arr.push(project.key)
            })
            data.project_ids = arr
          }
          updateDatabase(this.id, data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
