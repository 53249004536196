<template>
  <a-select
    :allow-clear="allowClear"
    :filter-option="false"
    :options="options"
    placeholder="请选择数据库类型"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
export default {
  name: 'DatabaseTypeSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: this.$t('database_type.mysql'), value: 'mysql' },
        { title: this.$t('database_type.postgresql'), value: 'postgresql' },
        { title: this.$t('database_type.oracle'), value: 'oracle' }
        // { title: this.$t('database_type.mssql'), value: 'MSSQL' },
        // { title: this.$t('database_type.redis'), value: 'redis' },
        // { title: this.$t('database_type.memcached'), value: 'memcached' },
        // { title: this.$t('database_type.mongodb'), value: 'mongodb' },
        // { title: this.$t('database_type.elasticsearch'), value: 'elasticsearch' }
      ]
    }
  }
}
</script>
